/* * @Author: liuzhixiang * @Date: 2021-02-06 15:18:34 * @Last Modified by:
liuzhixiang * @Last Modified time: 2021-02-06 15:18:34 */
<template>
  <div class="catalog">
    <!-- <div class="detail-title">课程目录</div> -->
    <div class="title_dom">课程目录</div>
    <div class="cont mytree">
      <el-tree
        :indent="25"
        default-expand-all
        highlight-current
        :expand-on-click-node="false"
        :data="coursePackage"
        :props="defaultProps"
        @node-click="handleNodeClick"
      >
        <div class="custom-tree-node" slot-scope="{ node, data }">
          <span>{{ node.label }}</span>
          <span v-if="data.Duration != null">
            <i class="bf"></i>
            {{ data.Duration | sec_to_time }}
          </span>
        </div>
      </el-tree>

      <!-- <div v-for="(cp, i) in coursePackage" :key="i">
        <div class="title">
          <p>{{ cp.MenuName }}</p>
        </div>
        <div v-for="(cpc, k) in cp.children" :key="k">
          <div class="title td_12">{{ cpc.MenuName }} {{cpc.Id}}</div>
          <ul>
            <li v-for="(cpcc, j) in cpc.children" :key="j">
              <div class="left">
                <span>{{ cpcc.MenuName }}</span>
                <span>{{ cpcc.VideoName }}</span>
                <span v-if="cpcc.IsAudition == 1">试听</span>
              </div>
              <div class="right">
                <i></i>
                <span>{{ cpcc.Duration }}</span>
              </div>
            </li>
          </ul>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { courseQueryCourseMenu } from "@/api/course";
import noData from "@/components/noData";
export default {
  props: ["coursePackage", "course"],
  data () {
    return {
      catalogs: [],
      defaultProps: {
        label: "MenuName",
        children: "children",
      },
    };
  },
  components: {
    noData,
  },
  created () { },
  mounted () {
    for (let i = 0; i < this.coursePackage.length; i++) {
      this.$set(this.coursePackage[i], "MenuName", this.coursePackage[i].Name);
      this.$set(this.coursePackage[i], "children", []);
      this.getCourseQueryCourseMenu(this.coursePackage[i].Id, i);
    }
  },
  methods: {
    handleNodeClick (data) {
      // console.log(data);
      // if (this.course.IsCharge == false && this.course.OriginalPrice == null) {
      if (this.course.IsCharge == false) {
        this.course.CoursId = this.course.Id;
        this.course.CourseName = this.course.Name;
        let parm = {
          CoursId: this.course.Id,
          CourseName: this.course.Name,
          IsCharge: this.course.IsCharge,
          CoverImg: this.course.CoverImg,
          TeacherName: this.course.TeacherName,
        };
        this.$router.push({ path: "/onDemand", query: parm });
      } else {
        if (localStorage.getItem("userInfo")) {
          let parm = {
            CoursId: this.course.Id,
            CourseName: this.course.Name,
            IsCharge: this.course.IsCharge,
            CoverImg: this.course.CoverImg,
            TeacherName: this.course.TeacherName,
          };
          this.$router.push({ path: "/onDemand", query: parm });
        } else {
          this.$confirm("收费课程请先登录?", "提示", {
            confirmButtonText: "去登录",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.$router.push("/login");
            })
            .catch(() => { });
          return false;
        }
      }
    },
    getPackage (arr, obj) {
      if (arr !== undefined) {
        arr.forEach((item, i) => {
          obj.forEach((it, j) => {
            if (item.Id === it.ParentId) {
              if (item.children === undefined) {
                this.$set(item, "children", []);
              }
              item.children.push(it);
            }
          });
          this.getPackage(item.children, obj);
        });
      }
    },
    async getCourseQueryCourseMenu (id, i) {
      const res = await courseQueryCourseMenu(id);
      if (res.success === true) {
        let obj = res.response?.data ? res.response?.data : [];
        // console.log(obj, '')
        if (obj.length > 0) {
          let arr = obj.filter((lis) => {
            if (lis.ParentId == 0) {
              this.$set(lis, "children", []);
              return lis;
            }
          });
          // console.log(this.coursePackage)
          this.coursePackage[i].children = arr;
          this.getPackage(this.coursePackage, obj);
        }
      } else {
        this.$message.error(res.msg);
      }
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-tree-node__content {
  min-height: 60px;
  border-bottom: 1px solid #e6e9ed;
}

.td_12 {
  text-indent: 12px;
}
.catalog {
  margin: 10px 0;
  .cont {
    background-color: #fff;
    padding: 20px 30px 50px;
    /deep/.el-tree {
      /deep/.el-tree-node__content {
        height: 30px;
      }
      .custom-tree-node {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 8px;
        .bf {
          display: inline-block;
          width: 12px;
          height: 12px;
          background-size: 100% 100%;
          background-image: url("../../../assets/images/icon_play.png");
          margin-right: 5px;
          vertical-align: middle;
        }
      }
    }
    .title {
      padding: 20px 30px 0;
      p {
        font-weight: bold;
        line-height: 52px;
        font-size: 16px;
        height: 52px;
        background-color: #f5f6f6;
      }
    }
    ul {
      padding: 0 30px 1px;
      li {
        line-height: 24px;
        font-size: 16px;
        margin: 30px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-indent: 24px;
        .left {
          span {
            display: inline-block;
            &:nth-child(1) {
              color: #888888;
            }
            &:nth-child(2) {
              margin: 0 20px;
            }
            &:nth-child(3) {
              width: 48px;
              height: 24px;
              line-height: 24px;
              color: #ffffff;
              text-align: center;
              background: #ffa016;
              border-radius: 4px;
            }
          }
        }
        .right {
        }
      }
    }
  }
  .detail-title {
    overflow: hidden;
    line-height: 16px;
    height: 30px;
    font-size: 16px;
    margin-bottom: 5px;
    color: #676767;
    position: relative;
    padding-top: 15px;
    padding-left: 30px;
  }
  .detail-title:after {
    position: absolute;
    width: 2px;
    height: 30px;
    background: #3c81f9;
    left: 20px;
    top: 15px;
    content: "";
  }
}
</style>
