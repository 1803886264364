/* * @Author: liuzhixiang * @Date: 2021-02-06 14:41:28 * @Last Modified by:
liuzhixiang * @Last Modified time: 2021-03-03 18:12:57 */
<template>
  <div class="course_details">
    <div class="top">
      <div class="width_1200" style="padding-top: 40px">
        <div class="top_cont">
          <img v-lazy="course.CoverImg" class="tc_left" alt />
          <div class="tc_right">
            <p class="title">{{ course.Name }}</p>
            <div
              class="kc"
              v-if="course.IsCharge === true && course.OriginalPrice != null"
            >
              <span>类型：</span>
              <p :class="isVip == 0 ? 'active' : ''" @click="courseType(0)">
                标准课程
              </p>
              <p
                v-if="course.VipPrice != null"
                :class="isVip == 1 ? 'active' : ''"
                @click="courseType(1)"
              >
                Vip课程
              </p>
            </div>
            <p class="time">有效期：{{ course.ValidityDateEnd | dataYMD }}</p>
            <p class="price">
              <span
                class="c_F40707 originalPrice"
                v-if="
                  course.IsCharge === true &&
                  course.OriginalPrice != null &&
                  isVip == 0
                "
              >
                <span>￥</span>{{ course.OriginalPrice | priceToFixed }}
                <span class="showPrice" v-if="course.ShowPrice"
                  >￥{{ course.ShowPrice | priceToFixed }}</span
                >
              </span>
              <span
                v-if="
                  course.IsCharge === false &&
                  course.OriginalPrice === null &&
                  course.VipPrice === null &&
                  isVip === 0
                "
                class="mf c_28B28B"
                >免费</span
              >
              <span
                class="c_F40707 originalPrice"
                v-if="course.VipPrice != null && isVip == 1"
              >
                <span>￥</span>{{ course.VipPrice | priceToFixed }}
                <span class="showPrice" v-if="course.ShowPrice"
                  >￥{{ course.ShowVipPrice | priceToFixed }}</span
                >
              </span>
              <!-- <span
                class="c_FFA11A"
                v-if="course.VipPrice != null && isVip == 1"
              >
                <img src="../../assets/images/vip.png" alt />
                VIP会员：¥{{ course.VipPrice | priceToFixed }}元
              </span> -->
            </p>
            <div
              class="btn"
              v-if="course.IsCharge === true && course.OriginalPrice != null"
            >
              <el-button @click="buy(course)" class="buy">立即购买</el-button>
              <el-button @click="joinShop()" class="join">加入购物车</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="cont">
      <div class="cont_top width_1200">
        <div class="width_1200" style="margin-left: 20px">
          <span
            :class="titlesIndex == i ? 'active' : ''"
            v-for="(ti, i) in titles"
            :key="i"
            @click="titlesClick(i)"
            >{{ ti }}</span
          >
        </div>
      </div>
      <div class="width_1200">
        <!-- 课程概览 -->
        <overview
          v-if="courseIndex == 0 && course.Id"
          :course="course"
        ></overview>
        <!-- 课程目录 -->
        <catalog
          v-if="courseIndex == 1 && course.Id"
          :coursePackage="coursePackage"
          :course="course"
        ></catalog>
        <!-- 课程资料 -->
        <courseData
          v-if="courseIndex == 2 && course.Id"
          :coursePackage="coursePackage"
          :course="course"
        ></courseData>
        <!-- 习题 -->
        <exercises
          v-if="courseIndex == 3 && course.Id"
          :course="course"
        ></exercises>
      </div>
      <!-- 教师资料 -->
      <!-- <teacher-information
        v-if="courseIndex == 3 && course.Id"
        :teacherInfo="course.TeacherInfo[0]"
      ></teacher-information> -->
    </div>
    <!-- 弹窗选择 -->
    <dialog-Visible
      :dialogVisible="dialogVisible"
      :title="dialogTitle"
      @close="getClose()"
    ></dialog-Visible>
  </div>
</template>
<script>
import overview from "./components/overview";
import catalog from "./components/catalog";
import courseData from "./components/courseData";
import teacherInformation from "./components/teacherInformation";
import exercises from "./components/exercises";
import dialogVisible from "./components/dialogVisible";
import { orderSaveCourseCar } from "@/api/order";
import {
  courseQueryPackageDetail,
  courseQueryCourseDetail,
} from "@/api/course";
export default {
  data () {
    return {
      titles: ["概览", "目录", "资料", "习题"],// 教师资料
      titlesIndex: 0,
      courseIndex: 0,
      course: {},
      coursePackage: [],
      dialogVisible: false,
      dialogTitle: "",
      isVip: 0,
      centerDialogVisible: false,
    };
  },
  components: {
    overview,
    catalog,
    courseData,
    dialogVisible,
    teacherInformation,
    exercises
  },
  created () { },
  mounted () {
    this.init();
  },
  methods: {
    handleClose () {
      this.centerDialogVisible = false;
    },
    courseType (i) {
      this.isVip = i;
    },
    // 弹窗关闭
    getClose () {
      this.dialogVisible = false;
    },
    // 加入购课车
    async joinShop () {
      let parm = {
        goodsId: this.course.Id,
        isVip: this.isVip, //0标准课程1Vip课程
        quantity: 1,
        GoodsCategory: 0, //0课程1资料2题库
      };
      const res = await orderSaveCourseCar(parm);
      if (res.success === true) {
        this.$message.success(res.msg);
      } else {
        this.$message.error(res.msg);
      }
      // if (this.course.VipPrice > 0) {
      //   // this.dialogTitle = "加入购课车"
      //   // this.dialogVisible = true;
      // }else{
      //    let parm = {
      //     goodsId: this.course.Id,
      //     isVip: 0,//0标准课程1Vip课程
      //     quantity: 1,
      //     GoodsCategory:0,//0课程1资料2题库
      //   };
      //   const res = await orderSaveCourseCar(parm);
      //   if (res.success === true) {
      //     this.$message.success(res.msg);
      //   } else {
      //     this.$message.error(res.msg);
      //   }
      // }
    },
    async init () {
      let course = this.$route.query;
      this.getcourseQueryCourseDetail(course.Id);
    },
    //获取课程包详情
    async getcourseQueryCourseDetail (id) {
      const res = await courseQueryCourseDetail(id);
      if (res.success === true) {
        this.course = res.response;
        this.coursePackage = [res.response];
        // coursePackage 课程包（0不是1是）
        if (this.course.CoursePackage === 1) {
          this.postCourseQueryPackageDetail(this.course.Id);
        }
      } else {
        this.$message.error(res.msg);
      }
    },
    //获取课程包详情
    async postCourseQueryPackageDetail (id) {
      const res = await courseQueryPackageDetail(id);
      if (res.success === true) {
        this.coursePackage = res.response;
      } else {
        this.$message.error(res.msg);
      }
    },
    //立即购买
    buy (obj) {
      if (localStorage.getItem("userInfo") == null) {
        this.$confirm("你还没登录不能购买课程?", "提示", {
          confirmButtonText: "去登录",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$router.push("/login");
          })
          .catch(() => { });
        return;
      }
      var userInfo = JSON.parse(this.$store.state.userInfo);
      if (!userInfo.IsIdentificate) {
        this.$confirm("你还没实名认证不能购买课程?", "提示", {
          confirmButtonText: "去实名",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$router.push("/personal/personalData");
          })
          .catch(() => { });
        return false;
      }
      if (this.course.VipPrice > 0) {
        // this.dialogTitle = "立即购买"
        // this.dialogVisible = true;
        this.course.IsVip = this.isVip; //是否Vip（1是0否）
        this.course.GoodsCategory = 0; //商品类型（0课程1资料2题库）
        this.course.Immediately = "1";
        this.$router.push({
          path: "/course/order",
          query: { data: JSON.stringify([this.course]) },
        });
      } else {
        obj.IsVip = "0"; //是否Vip（1是0否）
        obj.GoodsCategory = 0; //商品类型（0课程1资料2题库）
        obj.Immediately = "1";
        this.$router.push({
          path: "/course/order",
          query: { data: JSON.stringify([obj]) },
        });
      }
    },
    titlesClick (i) {
      this.titlesIndex = i;
      this.courseIndex = i;
    },
  },
};
</script>
<style lang="less" scoped>
.course_details {
  .top {
    min-width: 1200px;
    height: 370px;
    background-size: 100% 100%;
    // margin-top: -30px;
    background-image: url("../../assets/images/personal_banner_bg.png");
    .top_cont {
      // height: 300px;
      background-color: #ffffff;
      padding: 30px;
      display: flex;
      .tc_left {
        width: 535px;
        height: 240px;
        margin-right: 40px;
      }
      .tc_right {
        color: #333333;
        width: 556px;
        .title {
          line-height: 1.5;
          font-size: 24px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-weight: 400;
        }
        .price {
          font-size: 14px;
          line-height: 20px;
          display: flex;
          align-items: center;
          margin-top: 16px;
          margin-bottom: 25px;
          span {
            img {
              width: 12px;
              height: 12px;
              margin-right: 2px;
              vertical-align: middle;
            }
          }
          .mf {
            margin-right: 30px;
          }
          .originalPrice {
            font-size: 24px;
            margin-right: 30px;
            span {
              font-size: 0.625rem;
            }
          }
          .showPrice {
            color: #b5b4b4;
            font-size: 12px;
            text-decoration: line-through;
          }
        }
        .kc {
          display: flex;
          align-items: center;
          margin: 20px 0 0;
          p {
            display: inline-block;
            text-align: center;
            width: 75px;
            height: 24px;
            line-height: 24px;
            margin-right: 10px;
            cursor: pointer;
            border: 1px solid #ececec;
          }
          .active {
            border: 1px solid #3a78f9;
            color: #3a78f9;
          }
        }
        .time {
          margin: 20px 0;
          font-size: 16px;
          line-height: 100%;
        }
        .btn {
          .el-button {
            &:last-child {
              margin-left: 30px;
            }
          }
        }
      }
    }
  }
  .cont {
    .cont_top {
      height: 50px;
      background: #ffffff;
      span {
        display: inline-block;
        margin-right: 30px;
        line-height: 50px;
        font-size: 16px;
        cursor: pointer;
      }
      .active {
        color: #1288f4;
        position: relative;
        &::before {
          content: "";
          width: 100%;
          height: 1px;
          background-color: #1288f4;
          position: absolute;
          left: 0;
          bottom: 0;
        }
      }
    }
  }
  .detailContent {
    background-color: white;
  }
}
.buy {
  background-color: #3a78f9;
}
.join {
  background-color: #e5e6e6;
}
</style>
