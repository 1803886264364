/*
 * @Author: liuzhixiang 
 * @Date: 2021-02-06 15:29:13 
 * @Last Modified by: liuzhixiang
 * @Last Modified time: 2021-09-01 16:56:26
 */
import http from './http'
// 
/**
 *  @parms resquest 请求地址 例如：http://197.82.15.15:8088/request/...
 *  @param '/testIp'代表vue-cil中config，index.js中配置的代理
 */
let resquest = "/api/api/v1"

// 获取课程目录（单课程）
export async function courseQueryCourseMenu(params) {
  let res = await http.get(`${resquest}/Course/QueryCourseMenu?courseId=` + params);
  return res.data;
}

// 获取课程资料（单课程）
export async function courseQueryCourseFileList(params) {
  let res = await http.get(`${resquest}/Course/QueryCourseFileList`, params);
  return res.data;
}

// 获取课程包详情
export async function courseQueryPackageDetail(params) {
  let res = await http.get(`${resquest}/Course/QueryPackageDetail?id=` + params);
  return res.data;
}

// 课程详情（课程和课程包通用）
export async function courseQueryCourseDetail(params) {
  let res = await http.get(`${resquest}/Course/QueryCourseDetail?courseId=` + params);
  return res.data;
}

// 根据id获取资料详情
export async function courseQueryCourseFileDetail(params) {
  let res = await http.get(`${resquest}/Course/QueryCourseFileDetail?id=` + params);
  return res.data;
}

// 获取课程分类树形列表
export async function courseQueryCourseCategoryTree(params) {
  let res = await http.get(`${resquest}/Course/QueryCourseCategoryTree`, params);
  return res.data;
}

// 获取课程评论
export async function courseQueryCourseComment(params) {
  let res = await http.post(`${resquest}/Course/QueryCourseComment`, params);
  return res.data;
}

// 资料类别列表
export async function courseQueryCourseFileTypeList(params) {
  let res = await http.get(`${resquest}/Course/QueryCourseFileTypeList` + params);
  return res.data;
}