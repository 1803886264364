/*
 * @Author: liuzhixiang 
 * @Date: 2021-02-06 14:16:30 
 * @Last Modified by: liuzhixiang
 * @Last Modified time: 2021-09-01 18:56:45
 */
<template>
  <div class="courseData">
    <!-- <div class="top">课程资料</div> -->
    <div class="top">
      <div class="title_dom">资料列表</div>
      <ul>
        <li
          v-for="(rl, i) in rollUps"
          :key="i"
          :class="contactsIndex == i ? 'active' : ''"
          @click="contactsClick(rl, i)"
        >
          {{ rl }}
        </li>
      </ul>
    </div>
    <div class="cont">
      <!-- <div class="title">{{ cp.Name }}</div> -->
      <div v-if="list.length > 0">
        <el-row
          type="flex"
          align="middle"
          justify="center"
          v-for="(li, i) in list"
          :key="i"
        >
          <el-col :span="22">
            <div class="name">{{ li.Name }} <span>501题</span></div>
            <div class="price">
              <p class="c_1288F4" v-if="li.Category == 2">免费</p>
              <p
                class="c_F40707"
                v-else-if="li.IsSale != 1 && li.Category == 0"
              >
                课程专属
              </p>
              <p
                class="c_F40707"
                v-else-if="li.IsSale != 1 && li.Category == 1"
              >
                VIP专属
              </p>
              <p class="c_F40707" v-else-if="li.IsSale == 1">
                <span>￥</span>{{ li.Price | priceToFixed }}
              </p>
            </div>
          </el-col>
          <el-col :span="2" class="text_align_right">
            <div @click="parcticeClick(li)">练习</div>
          </el-col>
        </el-row>
      </div>
      <div v-else>
        <no-data></no-data>
      </div>
    </div>
    <el-pagination
      v-if="list.length > 0"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="page"
      :page-size="pageSize"
      layout="prev, pager, next"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
import { questionBankQueryPaperList } from "@/api/questionBank";
import noData from "@/components/noData";
export default {
  props: ["coursePackage", "course"],
  data () {
    return {
      rollUps: ["全部", "模拟考试", "历年真题", "专项练习"],
      list: [],
      page: 1,
      pageSize: 10,
      total: 0,
      types: [],
      contactsIndex: 0,
      paperType: ""
    };
  },
  components: {
    noData
  },
  created () { },
  mounted () {
    this.postQuestionBankQueryPaperList()
  },
  methods: {

    parcticeClick (obj) {
      let ob = {
        Id: obj.Id,
      };
      this.$router.push({
        path: "/onlineQuestionBank/practiceDetails",
        query: ob,
      });
    },
    //考题类型
    contactsClick (obj, i) {
      this.contactsIndex = i;
      if (this.contactsIndex == 0) {
        this.paperType = "";
      } else {
        this.paperType = this.contactsIndex - 1
      }
      this.postQuestionBankQueryPaperList();
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`);
      this.page = val;
      this.postQuestionBankQueryPaperList();
    },
    //列表
    async postQuestionBankQueryPaperList () {
      let parm = {
        courseId: this.course.Id,
        pageIndex: this.page,
        pageSize: this.pageSize,
        PaperType: this.paperType, //试卷类型，不传时全部，0模拟试卷，1真题
      };
      const res = await questionBankQueryPaperList(parm);
      if (res.success === true) {
        this.list = res.response.data;
        this.total = res.response.dataCount;
      } else {
        this.$message.error(res.msg);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.courseData {
  font-size: 16px;
  .title_dom {
  }
  .top {
    // line-height: 60px;
    // height: 60px;
    // padding: 0 20px;
    // margin: 20px 0;
    // background-color: #ffffff;
    display: flex;
    align-items: center;
    ul {
      margin-left: 60px;
      display: flex;
      flex-wrap: wrap;
      margin-top: 5px;
      li {
        min-width: 80px;
        height: 30px;
        line-height: 30px;
        padding: 0 10px;
        text-align: center;
        color: #747774;
        font-size: 14px;
        margin-right: 20px;
        border-radius: 30px;
        background-color: #fff;
      }
      .active {
        color: #fff;
        background-color: #1288f4;
      }
    }
  }
  .cont {
    padding: 10px 30px;
    background-color: #ffffff;
    .title {
      border-bottom: 1px solid #efefef;
      height: 55px;
      line-height: 55px;
      padding: 0 20px;
    }
    .cont_title {
      font-weight: bold;
      line-height: 52px;
      font-size: 16px;
      height: 52px;
      background-color: #f5f6f6;
      margin: 10px 20px 0;
    }
    .el-row {
      line-height: 40px;
      border-bottom: 1px solid #efefef;
      .el-col {
        cursor: pointer;
        &:first-child {
          // padding-left: 40px;
          .name {
            font-weight: bold;
            font-size: 16px;
            span {
              display: inline-block;
              padding: 0 8px;
              line-height: 18px;
              min-width: 26px;
              background-color: #e2ebfe;
              border-radius: 30px;
              text-align: center;
              font-weight: normal;
              font-size: 0.5rem;
              color: #3a78f9;
              margin-left: 5px;
            }
          }
          .price {
            margin: 5px 0 15px;
            span {
              font-size: 0.625rem;
            }
          }
        }
        &:last-child {
          // padding-right: 40px;
          margin: 0 auto;
          div {
            width: 88px;
            height: 36px;
            background-color: #1288f4;
            border-radius: 30px;
            color: #fff;
            text-align: center;
          }
        }
      }
    }
  }
}
.el-pagination {
  text-align: right;
}
</style>