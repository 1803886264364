/*
 * @Author: liuzhixiang 
 * @Date: 2021-02-06 15:18:49 
 * @Last Modified by:   liuzhixiang 
 * @Last Modified time: 2021-02-06 15:18:49 
 */
<template>
  <div class="course_dialogVisible">
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="40%"
      center
      :before-close="handleClose"
    >
      <el-row type="flex" align="middle">
        <el-col :span="4">购买类型：</el-col>
        <el-col :span="20">
          <el-radio v-model="radio" label="0">标准课程</el-radio>
          <el-radio v-model="radio" label="1">Vip课程</el-radio></el-col
        >
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose()">取 消</el-button>
        <el-button type="primary" @click="handleFind()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { orderSaveCourseCar } from "@/api/order";
export default {
  props: ["dialogVisible","title"],
  data() {
    return {
      radio: "0",
    };
  },
  created() {},
  mounted() {
    this.radio = "0";
  },
  methods: {
    handleFind(){
      this.course = this.$route.query;
      if (this.title == "加入购课车") {
        this.postOrderSaveCourseCar();
      } else {
        this.course.IsVip = this.radio;//是否Vip（1是0否）
        this.course.GoodsCategory = 0;//商品类型（0课程1资料2题库）
        this.course.Immediately = "1";
        this.$router.push({ path: "/course/order", query: {data: JSON.stringify([this.course]) }});
      }
    },
    //加入购物车
    async postOrderSaveCourseCar(){
      let parm = {
          goodsId: this.course.Id,
          isVip: this.radio,//0标准课程1Vip课程
          quantity: 1,
          GoodsCategory:0,//0课程1资料2题库
        };
        const res = await orderSaveCourseCar(parm);
        if (res.success === true) {
          this.$message.success(res.msg);
          this.$emit("close");
        } else {
          this.$message.error(res.msg);
        }
    },
    handleClose() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="less" scoped>
.overview {
  margin: 20px;
  line-height: 150%;
}
</style>