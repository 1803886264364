/*
 * @Author: liuzhixiang 
 * @Date: 2021-02-06 14:16:30 
 * @Last Modified by: liuzhixiang
 * @Last Modified time: 2021-09-01 18:37:12
 */
<template>
  <div class="courseData">
    <!-- <div class="top">课程资料</div> -->
    <div class="top">
      <div class="title_dom">资料列表</div>
      <ul>
        <li
          v-for="(item, i) in types"
          :class="typeId == item.Id ? 'active' : ''"
          :key="i"
          @click="tyClick(item)"
        >
          {{ item.FileCategoryName }}
        </li>
      </ul>
    </div>
    <div class="cont">
      <!-- <div class="title">{{ cp.Name }}</div> -->
      <div v-if="courseData.length > 0">
        <ul>
          <li
            v-for="(cd, i) in courseData"
            :key="i"
            @click="courseDatasClick(cd)"
          >
            <div class="left">
              <img
                src="@/assets/images/icon_pdf.png"
                v-if="cd.suffix === 'pdf'"
                width="55"
                height="60"
                alt=""
              />
              <img
                src="@/assets/images/icon_execl.png"
                v-else-if="cd.suffix == 'excel'"
                width="55"
                height="60"
                alt=""
              />
              <img
                src="@/assets/images/icon_pic.png"
                v-else-if="
                  cd.suffix == 'png' ||
                  cd.suffix == 'jpg' ||
                  cd.suffix == 'jpeg'
                "
                width="55"
                height="60"
                alt=""
              />
              <img
                src="@/assets/images/icon_word.png"
                v-else-if="cd.suffix == 'word'"
                width="55"
                height="60"
                alt=""
              />
              <img
                src="@//assets/images/icon_zip.png"
                width="55"
                height="60"
                v-else
                alt=""
              />
              <div class="zlxq">
                <p>{{ cd.FileName }}</p>
                <p>
                  有效期：
                  <!-- {{ cd.FileValidityDateStart | dataYMD }}至 -->
                  {{ cd.FileValidityDateEnd | dataYMD }}
                </p>
              </div>
            </div>
            <div class="right">
              <p class="c_1288F4" v-if="cd.Category == 2">免费</p>
              <p
                class="c_F40707"
                v-else-if="cd.IsSale != 1 && cd.Category == 0"
              >
                课程专属
              </p>
              <p
                class="c_F40707"
                v-else-if="cd.IsSale != 1 && cd.Category == 1"
              >
                VIP专属
              </p>
              <p class="c_F40707" v-else-if="cd.IsSale == 1">
                ￥{{ cd.Price | priceToFixed }}
              </p>
              <!-- <p class="price c_F40707">￥{{cd.Price}}</p>
            <p class="vip_price"><i></i> VIP会员：¥88.00元</p>
            <p>唯一支持会员购买</p> -->
            </div>
          </li>
        </ul>
      </div>
      <div v-else>
        <no-data></no-data>
      </div>
    </div>
    <el-pagination
      v-if="courseData.length > 0"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="page"
      :page-size="pageSize"
      layout="prev, pager, next"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
import { courseQueryCourseFileTypeList, courseQueryCourseFileList } from "@/api/course";
import noData from "@/components/noData";
export default {
  props: ["coursePackage", "course"],
  data () {
    return {
      courseData: [],
      page: 1,
      pageSize: 10,
      total: 0,
      types: [],
      typeId: null
    };
  },
  components: {
    noData
  },
  created () { },
  mounted () {
    // console.log(this.course, '')
    // for (let i = 0; i < this.coursePackage.length; i++) {
    //   this.$set(this.coursePackage[i], "courseDatas", []);
    //   this.postCourseQueryCourseFileList(this.coursePackage[i].Id, i);
    // }
    this.getCourseQueryCourseFileTypeList()
  },
  methods: {
    tyClick (obj) {
      this.courseData = []
      this.typeId = obj.Id
      this.postCourseQueryCourseFileList()
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`);
      this.page = val;
      this.postCourseQueryCourseFileList()
    },
    courseDatasClick (obj) {
      let ob = {
        Id: obj.Id,
      }
      this.$router.push({ path: "../../dataDownload/details", query: ob });
    },
    //课程列表
    async getCourseQueryCourseFileTypeList () {
      let parm = "?courseId=" + this.course.Id
      const res = await courseQueryCourseFileTypeList(parm);
      if (res.success === true) {
        this.types = res.response
        this.typeId = this.types[0].Id
        this.postCourseQueryCourseFileList()
      } else {
        this.$message.error(res.msg);
      }
    },
    //课程资料
    async postCourseQueryCourseFileList () {
      let parm = {
        courseId: this.course.Id,
        FileType: this.typeId,
        pageIndex: this.page,
        pageSize: this.pageSize,
      };
      const res = await courseQueryCourseFileList(parm);
      if (res.success === true) {
        // this.coursePackage[i].courseDatas = res.response?.data ? res.response?.data : [];
        this.courseData = res.response?.data
        this.courseData.forEach(item => {
          let suffix = item.FileUrl.split('.').pop().toLowerCase()
          this.$set(item, "suffix", suffix)
        })
        this.total = res.response.dataCount
      } else {
        this.$message.error(res.msg);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.courseData {
  font-size: 16px;
  .title_dom {
  }
  .top {
    // line-height: 60px;
    // height: 60px;
    // padding: 0 20px;
    // margin: 20px 0;
    // background-color: #ffffff;
    display: flex;
    align-items: center;
    ul {
      margin-left: 60px;
      display: flex;
      flex-wrap: wrap;
      margin-top: 5px;
      li {
        min-width: 80px;
        height: 30px;
        line-height: 30px;
        padding: 0 10px;
        text-align: center;
        color: #747774;
        font-size: 14px;
        margin-right: 20px;
        border-radius: 30px;
        background-color: #fff;
      }
      .active {
        color: #fff;
        background-color: #1288f4;
      }
    }
  }
  .cont {
    margin: 10px 0;
    background-color: #ffffff;
    .title {
      border-bottom: 1px solid #efefef;
      height: 55px;
      line-height: 55px;
      padding: 0 20px;
    }
    .cont_title {
      font-weight: bold;
      line-height: 52px;
      font-size: 16px;
      height: 52px;
      background-color: #f5f6f6;
      margin: 10px 20px 0;
    }
    ul {
      padding: 10px 0;
      li {
        padding: 20px 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #efefef;
        &:last-child {
          border-bottom: 0;
        }
        .left {
          display: flex;
          img {
            // width: 128px;
            height: 60px;
            margin-right: 30px;
          }
          .zlxq {
            p {
              line-height: 100%;
              &:nth-child(1) {
              }
              &:nth-child(2) {
                font-size: 14px;
                color: #999999;
                margin-top: 27px;
              }
            }
          }
        }
        .right {
          p {
            text-align: center;
            &:nth-child(n + 2) {
              margin-top: 20px;
            }
          }
          .vip_price {
            font-size: 14px;
            color: #ffa11a;
            i {
              display: inline-block;
              width: 12px;
              height: 12px;
              background-size: 100% 100%;
              background-image: url("../../../assets/images/vip.png");
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
}
.el-pagination {
  text-align: right;
}
</style>