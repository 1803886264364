<template>
  <div class="overview">
    <!-- <div class="detail-title">课程介绍</div>
    <div class="courseRemark" v-if="course.Remark" v-html="course.Remark"></div>
    <div v-else>
      <no-data></no-data>
    </div> -->
    <div v-if="course.TeacherInfo.length > 0">
      <div class="title_dom">课程讲师</div>
      <ul class="teacherInfo">
        <li
          v-for="(item, index) in course.TeacherInfo"
          :key="index"
          @click="goToTeacherDetail(item.Id)"
        >
          <div>
            <img v-lazy="item.HeadImg" alt="" srcset="" />
            <p>{{ item.Name }}</p>
          </div>
          <div class="decimalPoint-3">{{ item.ShortTitle }}</div>
        </li>
      </ul>
    </div>
    <div v-if="course.Remark">
      <div class="title_dom">课程简介</div>
      <div class="courseRemark" v-html="course.Remark"></div>
    </div>
    <div v-if="list.length > 0">
      <div class="title_dom">学员评价</div>
      <div class="evaluate">
        <ul>
          <li v-for="(item, index) in list" :key="index">
            <img v-lazy="GLOBAL.hostUrl + item.HeadImg" alt="" srcset="" />
            <div>
              <div class="name">{{ item.StudentName }}</div>
              <div class="content">{{ item.Remark }}</div>
              <div class="time">{{ item.CreateTime }}</div>
            </div>
          </li>
        </ul>
        <div class="more" v-if="list.length < total">
          <span @click="more">查看更多</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import noData from "@/components/noData";
import { courseQueryCourseComment } from "@/api/course";
export default {
  props: ["course"],
  data () {
    return {
      page: 1,
      pageSize: 10,
      list: [],
      total: 0
    };
  },
  components: {
    noData,
  },
  created () {
  },
  mounted () {
    this.postCourseQueryCourseComment()
  },
  methods: {
    goToTeacherDetail (id) {
      this.$router.push({ path: '/teacher/teacherDetails', query: { id: id, type: 1 } });
    },
    more () {
      this.page++
      this.postCourseQueryCourseComment()
    },
    async postCourseQueryCourseComment () {
      let parm = {
        courseId: this.course.Id,
        pageIndex: this.page,
        pageSize: this.pageSize,
      }
      const res = await courseQueryCourseComment(parm);
      if (res.success === true) {
        this.list = this.list.concat(res.response.data)
        this.total = res.response.dataCount
      } else {
        this.$message.error(res.msg);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.overview {
  margin: 10px;
  line-height: 150%;
  min-height: 250px;
  margin-bottom: 20px;
}
.courseRemark {
  // margin: 10px;
  padding: 30px;
  background-color: #fff;
  line-height: 1.5;
}
.noData {
  padding-bottom: 100px !important;
}
.teacherInfo {
  display: flex;
  flex-wrap: wrap;
  li {
    flex: 0 0 575px;
    width: 575px;
    height: 120px;
    margin-right: 30px;
    margin-bottom: 30px;
    background-color: #ffffff;
    display: flex;
    &:nth-child(2n) {
      margin-right: 0;
    }
    div {
      &:nth-child(1) {
        flex: 0 0 140px;
        width: 140px;
        text-align: center;
        border-right: 1px solid #e7e7e7;
        img {
          width: 60px;
          height: 60px;
          border-radius: 50%;
          margin: 14px 0 6px;
        }
        p {
          line-height: 1;
          font-size: 16px;
          font-weight: 400;
        }
      }
      &:nth-child(2) {
        font-size: 14px;
        line-height: 1.5;
        margin: 30px;
      }
    }
  }
}
.evaluate {
  padding: 10px 0;
  background-color: #fff;
  ul {
    li {
      padding: 20px 30px;
      color: #6b666b;
      display: flex;
      img {
        flex: 0 0 40px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 15px;
      }
      .name {
      }
      .content {
        font-size: 16px;
        line-height: 1.5;
        margin: 10px 0;
      }
      .time {
        color: #999999;
      }
    }
  }
  .more {
    border-top: 1px solid #eff0f0;
    padding: 20px 0;
    text-align: center;
    font-size: 16px;
    color: #3a78f9;
    cursor: pointer;
  }
}
.detail-title {
  overflow: hidden;
  line-height: 16px;
  height: 30px;
  font-size: 16px;
  margin-bottom: 5px;
  color: #676767;
  position: relative;
  padding-top: 15px;
  padding-left: 20px;
}
.detail-title:after {
  position: absolute;
  width: 2px;
  height: 30px;
  background: #3c81f9;
  left: 10px;
  top: 15px;
  content: "";
}
</style>